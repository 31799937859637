* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: monospace;
}

#hero-image {
  background-image: url("./assets/hero-image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}
